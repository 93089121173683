import { BUSINESS_HOURS_OPTIONS, DEADLINE_HOLIDAYS_OPTIONS } from "@/config/constants"

export default {
  components: {
    Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
    AppDropdown: () => import("@/components/elements/AppDropdown"),
    AppNumberInput: () => import("@/components/elements/AppNumberInput")
  },

  props: {
    object: Object
  },

  data() {
    return {
      businessHoursOptions: BUSINESS_HOURS_OPTIONS,
      deadlineHolidaysOptions: DEADLINE_HOLIDAYS_OPTIONS
    }
  },

  computed: {
    hoursBeforeError() {
      return this.object.hours_before.$error
    },

    endDayError() {
      return this.object.end_day.$error
    },

    oClockError() {
      return this.object.o_clock.$error
    }
  },

  methods: {
    handleSelect({ id }, attributeName) {
      this.object[attributeName].$model = id
      this.resetAttributes()
      this.object.$reset()
    },

    resetAttributes() {
      this.object.end_day.$model = null
      this.object.hours_before.$model = null
      this.object.o_clock.$model = null
    }
  }
}
